import { createContext, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
export const DataContext = createContext()

export const DataProvider = ({ children }) => {
  const [existingFilesData, setExistingFilesData] = useState([])
  const [workshopNameData, setWorkshopNameData] = useState('')
  const [promptData, setPromptData] = useState('')
  const [additionalPromptData, setadditionalPromptData] = useState('')
  const [outputFileNameData, setoutputFileNameData] = useState('')
  const [kbFilesData, setKbFileData] = useState([])
  const [uploadFileListData, setUploadFileListData] = useState([])
  const [slidesAddedData, setSlidesAddedData] = useState([])
  const [currentScreen, setCurrentScreen] = useState('WorkshopDetails')
  const [sections, setSections] = useState([])
  const [plainOptionSections, setPlainOptionSections] = useState([])
  const [customOptionSections, setCustomOptionSections] = useState([])
  const [createSnippetValue, setCreateSnippet] = useState([])
  const [selectedSection, setSelectedSection] = useState('')
  const [mermaidTextValue, setMermaidTextValue] = useState(``)
  const [sectionSelected, setSectionSelected] = useState(``)
  const [showWorkshopAssistantCancel, setShowWorkshopAssistantCancel] = useState(false)
  const [commonOutputID, setCommonOutputId] = useState('')
  const [addPagesCount, setAddPagesCount] = useState()
  const [chatActiveIndex, setChatActiveIndex] = useState()
  const [contextChat, setContextChat] = useState([])
  const [uploadAllFilesObjectList, setUploadAllFilesObjectList] = useState([])
  const [uploadAllFileList, setAllUploadFileList] = useState([])
  const [updateFile, setUpdateFile] = useState([])
  const [uploadImageList, setUploadImageList] = useState([])
  const [uploadedFileName, setUploadedFileName] = useState('')
  const [uploadImagePosition, setUploadImagePosition] = useState('')
  const [addImageClicked, setAddImageClicked] = useState(false)
  const [addImageIndex, setAddImageIndex] = useState(-1)
  const [ptg, setPtg] = useState(0)
  const [isUploaded, setIsUploaded] = useState(false)
  const uniqId = uuidv4()
  const [uuidConst] = useState(uniqId)
  const [loading, setLoading] = useState(false)
  const [updateExistingFileChecked, setUpdateExistingFileChecked] = useState()
  const [createNewFileChecked, setCreateNewFileChecked] = useState()
  const [maliciousFiles, setMaliciousFiles] = useState([])

  return (
    <DataContext.Provider
      value={{
        existingFilesData,
        setExistingFilesData,
        workshopNameData,
        setWorkshopNameData,
        promptData,
        setPromptData,
        additionalPromptData,
        setadditionalPromptData,
        outputFileNameData,
        setoutputFileNameData,
        kbFilesData,
        setKbFileData,
        uploadAllFileList,
        setAllUploadFileList,
        uploadFileListData,
        setUploadFileListData,
        slidesAddedData,
        setSlidesAddedData,
        currentScreen,
        setCurrentScreen,
        sections,
        setSections,
        plainOptionSections,
        setPlainOptionSections,
        customOptionSections,
        setCustomOptionSections,
        createSnippetValue,
        setCreateSnippet,
        selectedSection,
        setSelectedSection,
        mermaidTextValue,
        setMermaidTextValue,
        sectionSelected,
        setSectionSelected,
        showWorkshopAssistantCancel,
        setShowWorkshopAssistantCancel,
        commonOutputID,
        setCommonOutputId,
        uuidConst,
        addPagesCount,
        setAddPagesCount,
        chatActiveIndex,
        setChatActiveIndex,
        contextChat,
        setContextChat,
        uploadAllFilesObjectList,
        setUploadAllFilesObjectList,
        uploadImageList,
        setUploadImageList,
        uploadedFileName,
        setUploadedFileName,
        uploadImagePosition,
        setUploadImagePosition,
        addImageClicked,
        setAddImageClicked,
        addImageIndex,
        setAddImageIndex,
        ptg,
        setPtg,
        isUploaded,
        setIsUploaded,
        loading,
        setLoading,
        updateFile,
        setUpdateFile,
        updateExistingFileChecked,
        setUpdateExistingFileChecked,
        createNewFileChecked,
        setCreateNewFileChecked,
        maliciousFiles,
        setMaliciousFiles,
      }}
    >
      {children}
    </DataContext.Provider>
  )
}
