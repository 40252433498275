/* eslint-disable no-useless-escape */
import React, { useContext, useEffect, useState } from 'react'
import CreateRequestForm from '../../Components/create-request-form'
import { Button, ConfigProvider, Modal, Spin, Tabs, TabsProps, notification } from 'antd'
import AssistantBotComponent from '../../Components/AssistantBotComponent'
import './index.scss'
import BlockDiagram from '../../Components/BlockDiagram'
import getToken from '../../addTokenInterceptor'
import HeaderTileWorkshop from '../../Components/HeaderTileWorkshop'
import { DataContext } from '../../DataContext'
import { cancelWorkshopPopupDisclaimer, cancelWorkshoppopupHeader } from './constants'
import { getProjectNames } from '../../services/apiService'
import { makePrompApiCall, getRandomSecureNumber } from '../../services/prepareService'
import {
  postChatMessage,
  postElaborate,
  postRegenChatMessageWithContext,
  postRegenChatMessageWithoutContext,
  postSummarize,
} from '../../services/chatService'
import {
  elaborateText,
  regenerateWithoutContext,
  regerateText,
  summarizeText,
} from '../../Components/AssistantBotComponent/constants'
import moment from 'moment'
import { checkMaliciousFilesAPI, uploadFilesToSasUrls } from '../../services/fileUploadService'
import { API_GENERATE_SASURLS, API_MALICIOUS_FILE } from '../../urls'
import { v4 as uuidv4 } from 'uuid'
import { getUploadHeaders } from '../../services/headerconstants'

interface IChatObj {
  role: string | null
  response: string
  time: string
  recommendedTopic: string
  topicValue: string
}

const Prepare: React.FC = () => {
  const {
    workshopNameData,
    createSnippetValue,
    setCreateSnippet,
    setSelectedSection,
    setSectionSelected,
    setChatActiveIndex,
    showWorkshopAssistantCancel,
    setShowWorkshopAssistantCancel,
    setAddPagesCount,
    uuidConst,
    setCurrentScreen,
    contextChat,
    setContextChat,
    uploadImageList,
    setUploadImageList,
    setPtg,
    setUploadedFileName,
    setUploadImagePosition,
    uploadImagePosition,
    setAddImageClicked,
    setAddImageIndex,
    setIsUploaded,
    setLoading,
    setMaliciousFiles,
  } = useContext(DataContext)
  const [roleName, setroleName] = useState('' || null)
  const [question, setQuestion] = useState('')
  const [chat, setChat] = useState<IChatObj[]>([])
  const [lastQuestion, setLastQuestion] = useState('')
  const [loading, setLoader] = useState(false)
  const [requestLoader, setRequestLoader] = useState(false)
  const [recommendedTopic, setRecommendedTopic] = useState('')
  const [recommendedTopicValue, setRecommendedTopicValue] = useState('')
  const [DefaultLabel, setDefaultLabel] = useState('')
  const [industryTrends] = useState('')
  const [configurationsteps] = useState('')
  const [agenda] = useState('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [promptValue, setpromptValue] = useState('')
  const [currentActiveTab, setCurrentActiveTab] = useState('1')
  const [requestValue, setRequestValue] = useState('')
  const [inputValue, setInputValue] = useState({ name: '', parentId: 0 })
  const [parentList, setParentList] = useState<{ label: string; value: number }[]>([])
  const [IsModalOpen, setIsModalOpen] = useState(false)
  const [SelectKey, setSelectKey] = useState(0)
  const [showModalLoader, setShowModalLoader] = useState(false)
  const [projectNames, setProjectNames] = useState('')
  const { accessToken, idToken, projectId, user_email } = getToken()
  const [error, setError] = useState('')
  const [currentActiveIndex, setCurrentActiveIndex] = useState({ value: 0 })
  let Pid = 0

  useEffect(() => {
    if (contextChat) {
      setChat(contextChat)
    }
  }, [contextChat])

  const updateChat = (newChat: IChatObj[]) => {
    setChat(newChat)
    setContextChat(newChat)
  }

  const saveOnDone = () => {
    setShowWorkshopAssistantCancel(false)
    setCurrentScreen('fileUpload')
    let slides = 0
    createSnippetValue.forEach((element: any) => {
      if (element?.RecommendedTopic !== element?.name) {
        slides++
      }
    })
    setAddPagesCount(slides)
  }

  useEffect(() => {
    if (projectId && user_email) {
      const fetchProjectNames = async () => {
        try {
          const names = await getProjectNames(accessToken, idToken, projectId)
          setProjectNames(names)
        } catch (err) {
          if (err instanceof Error) {
            setErrorMessage(err.message)
          }
        }
      }
      fetchProjectNames()
    }
  }, [projectId, user_email])

  const handleValidation = (snippetName: string) => {
    const pattern = /^[\w\s\-!@#.,$[\]\{\}()\\<>|/%^&*+=:;?~`]+$/
    if (snippetName.match(pattern)) {
      return true
    }
    return false
  }

  const handleChangeval = (value: string | number, type: string) => {
    setInputValue({ ...inputValue, [type]: value })
  }

  const showModal = async (index: number) => {
    if (roleName) {
      setShowModalLoader(true)
      const secondDictionary = createSnippetValue
      if (!secondDictionary) {
        throw new Error(`HTTP error! status:`)
      } else {
        setShowModalLoader(false)
        let newOptions: { label: any; value: any }[] = []
        for (let i = 0; i < secondDictionary.length; i++) {
          if (secondDictionary[i].RecommendedTopic === chat[index]?.recommendedTopic)
            newOptions.push({ label: secondDictionary[i].name, value: secondDictionary[i].id })
        }
        if (newOptions.length > 0) {
          newOptions = newOptions.sort((a, b) => a.value - b.value)
          const lastOption = newOptions[newOptions.length - 1]
          lastOption && setDefaultLabel(lastOption.label)
          handleChangeval(lastOption.label, 'parentId')
          setInputValue({ name: '', parentId: lastOption.value })
          setSelectKey((prevKey) => prevKey + 1)
        }
        setParentList(newOptions)
      }
      if (!showModalLoader) {
        setError('')
        setIsModalOpen(true)
      }
    }
  }
  const generateOutput = async () => {
    let name = ''
    if (uploadImageList.length !== 0) {
      // eslint-disable-next-line prefer-destructuring
      name = uploadImageList[0].name
    }
    // showLoader()
    const uniqId = uuidConst
    const data = {
      uniq_id: uniqId,
      job_id: 0,
      project_id: projectId,
      workshop_name: workshopNameData,
      text_prompt: '',
      aiding_prompt: '',
      sections: [],
      existing_files: [],
      output_filename: '',
      wkb_file: [],
      input_files: [],
      wa_chat_images:
        name !== ''
          ? [
              {
                filename: name,
              },
            ]
          : [],
    }
    const API_URL = import.meta.env.VITE_KDD_API

    try {
      const response = await fetch(`${API_URL}/${API_GENERATE_SASURLS}`, {
        method: 'POST',
        headers: getUploadHeaders(accessToken, idToken, projectId),
        body: JSON.stringify(data),
      })
      if (!response?.ok) {
        const message = `An error has occurred: ${response.statusText}`
        throw new Error(message)
      } else {
        const result = await response.json()

        // Pass the filtered file list to upload
        await uploadFilesToSasUrls(result, uploadImageList, [])
        await checkIfFileIsMalicious()
      }
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message)
      }
    }
  }

  const checkIfFileIsMalicious = async () => {
    let name = ''
    if (uploadImageList.length !== 0) {
      // eslint-disable-next-line prefer-destructuring
      name = uploadImageList[0].name
    }
    const uniqId = uuidConst
    const data = {
      uuid: uniqId,
      input_files:
        name !== ''
          ? [
              {
                filename: name,
              },
            ]
          : [],
      input_file_category: 'WAchat_image',
    }
    try {
      const response = await checkMaliciousFilesAPI(idToken, accessToken, projectId, data)
      if (response.detail) {
        const { malicious_files, detail } = response

        if (malicious_files && malicious_files.length > 0) {
          await setMaliciousFiles(malicious_files)
          setErrorMessage(detail)
          setLoading(false)
          return
        }
      } else {
        await setMaliciousFiles([])
        // Proceed with other logic
        setLoading(false)
      }
    } catch (err) {
      console.error('Error uploading files:', err)
      setErrorMessage('An error occurred during upload. Please try again.')
      setLoading(false)
    }
  }

  const handleModalOk = async () => {
    setLoading(true)
    await generateOutput()
    setLoading(false)
    setPtg(0)
    if (handleValidation(inputValue.name)) {
      let root = false
      for (let i = 0; i < createSnippetValue.length; i++) {
        if (
          createSnippetValue[i].name === chat[currentActiveIndex.value]?.recommendedTopic &&
          createSnippetValue[i].name === createSnippetValue[i].RecommendedTopic
        )
          root = true
      }
      let parentLabel
      parentLabel = parentList.filter((item) => item.value === inputValue.parentId)[0]?.label
      if (parentLabel === undefined) parentLabel = chat[currentActiveIndex.value]?.recommendedTopic
      try {
        const secondDictionary = createSnippetValue
        if (!secondDictionary) {
          throw new Error(`Error in fetching session snippets`)
        } else {
          if (parentLabel === chat[currentActiveIndex.value]?.recommendedTopic && !root) {
            Pid = 0
          } else {
            const targetItem = secondDictionary.find(
              (item: { name: string | number; RecommendedTopic: string }) =>
                item.name === parentLabel,
            )
            Pid = targetItem.id
          }
          if (!root) {
            const parentNode = {
              name: chat[currentActiveIndex.value]?.recommendedTopic,
              text: chat[currentActiveIndex.value]?.response,
              parentId: Pid,
              sessionName: roleName,
              id: getRandomSecureNumber(1, 100),
              projectId: Number(projectId),
              RecommendedTopic: chat[currentActiveIndex.value]?.recommendedTopic,
              topicValue: requestValue,
            }
            setCreateSnippet((prevChat: any) => [
              ...prevChat,
              parentNode,
              {
                name: inputValue.name,
                text: chat[currentActiveIndex.value]?.response,
                parentId: parentNode.id,
                sessionName: roleName,
                id: getRandomSecureNumber(1, 100),
                projectId: Number(projectId),
                RecommendedTopic: chat[currentActiveIndex.value]?.recommendedTopic,
                topicValue: chat[currentActiveIndex.value].topicValue,
                imagePosition: uploadImagePosition,
                fileName: uploadImageList[0]?.name,
              },
            ])
          } else {
            setCreateSnippet((prevChat: any) => [
              ...prevChat,
              {
                name: inputValue.name,
                text: chat[currentActiveIndex.value]?.response,
                parentId: Pid,
                sessionName: roleName,
                id: getRandomSecureNumber(1, 100),
                projectId: Number(projectId),
                RecommendedTopic: chat[currentActiveIndex.value]?.recommendedTopic,
                topicValue: chat[currentActiveIndex.value].topicValue,
                imagePosition: uploadImagePosition,
                fileName: uploadImageList[0]?.name,
              },
            ])
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          setErrorMessage(error.message)
        }
      }
      setIsModalOpen(false) // close the modal
      setChatActiveIndex(-1)
      setInputValue({ name: '', parentId: 0 })
      setDefaultLabel('')
      setUploadedFileName('')
      setUploadImagePosition('')
      setUploadImageList([])
      setAddImageClicked(false)
      setAddImageIndex(-1)
      setIsUploaded(false)
    } else {
      setError('String should not contain quotes')
    }
  }

  const handleChange = (value: string, key: string) => {
    switch (key) {
      case 'roleName':
        setroleName(value)
        break
      case 'requestValue':
        setRequestValue(value)
        break
    }
  }

  const handleDropdownChange = (value: string) => {
    setpromptValue(value)
    setSectionSelected(value)
    setSelectedSection(value)
  }

  useEffect(() => {
    setroleName(workshopNameData)
  }, [workshopNameData])

  const generatePrompt = async () => {
    setRequestLoader(true)
    setRecommendedTopic(promptValue)
    setRecommendedTopicValue(requestValue)
    const latestQuestion = await makePrompApiCall(
      promptValue,
      requestValue,
      idToken,
      accessToken,
      projectId,
      user_email,
      roleName!,
    )

    if (latestQuestion) {
      setRequestLoader(false)
      setQuestion(latestQuestion)
    }
  }

  const sendChat = async () => {
    const tempChat = [...chat]
    tempChat.push({
      role: 'user',
      response: question,
      time: moment().format('hh:mm A'),
      recommendedTopic: recommendedTopic,
      topicValue: recommendedTopicValue,
    })
    updateChat(tempChat)
    setLastQuestion(question)
    setQuestion('')
    setLoader(true)
    const assistantMessage = await postChatMessage(
      chat,
      question,
      uuidConst,
      recommendedTopic,
      recommendedTopicValue,
      accessToken,
      idToken,
      projectId,
    )
    setLoader(false)
    setChat((prevChat: any) => [...prevChat, assistantMessage])
    setContextChat((prevContextChat: any) => [...prevContextChat, assistantMessage])
  }

  const sendRegenChatWithContext = async () => {
    const tempChat = [...chat]
    tempChat.push({
      role: 'user',
      response: regerateText,
      time: moment().format('hh:mm A'),
      recommendedTopic: recommendedTopic,
      topicValue: recommendedTopicValue,
    })
    updateChat(tempChat)
    setQuestion('')
    setLoader(true)
    const assistantMessage = await postRegenChatMessageWithContext(
      chat,
      uuidConst,
      recommendedTopic,
      recommendedTopicValue,
      accessToken,
      idToken,
      projectId,
    )
    setLoader(false)
    setChat((prevChat: any) => [...prevChat, assistantMessage])
    setContextChat((prevContextChat: any) => [...prevContextChat, assistantMessage])
  }

  const sendRegenChatWithoutContext = async () => {
    const tempChat = [...chat]
    tempChat.push({
      role: 'user',
      response: regenerateWithoutContext,
      time: moment().format('hh:mm A'),
      recommendedTopic: recommendedTopic,
      topicValue: recommendedTopicValue,
    })
    updateChat(tempChat)
    setQuestion('')
    setLoader(true)
    const assistantMessage = await postRegenChatMessageWithoutContext(
      lastQuestion,
      uuidConst,
      recommendedTopic,
      recommendedTopicValue,
      accessToken,
      idToken,
      projectId,
    )
    setLoader(false)
    setChat((prevChat: any) => [...prevChat, assistantMessage])
    setContextChat((prevContextChat: any) => [...prevContextChat, assistantMessage])
  }

  const sendElaborate = async () => {
    const tempChat = [...chat]
    tempChat.push({
      role: 'user',
      response: elaborateText,
      time: moment().format('hh:mm A'),
      recommendedTopic: recommendedTopic,
      topicValue: recommendedTopicValue,
    })
    updateChat(tempChat)
    setQuestion('')
    setLoader(true)
    const assistantMessage = await postElaborate(
      chat,
      uuidConst,
      recommendedTopic,
      recommendedTopicValue,
      accessToken,
      idToken,
      projectId,
    )
    setLoader(false)
    setChat((prevChat: any) => [...prevChat, assistantMessage])
    setContextChat((prevContextChat: any) => [...prevContextChat, assistantMessage])
  }

  const sendSummarize = async () => {
    const tempChat = [...chat]
    tempChat.push({
      role: 'user',
      response: summarizeText,
      time: moment().format('hh:mm A'),
      recommendedTopic: recommendedTopic,
      topicValue: recommendedTopicValue,
    })
    updateChat(tempChat)
    setQuestion('')
    setLoader(true)
    const assistantMessage = await postSummarize(
      chat,
      uuidConst,
      recommendedTopic,
      recommendedTopicValue,
      accessToken,
      idToken,
      projectId,
    )
    setLoader(false)
    setChat((prevChat: any) => [...prevChat, assistantMessage])
    setContextChat((prevContextChat: any) => [...prevContextChat, assistantMessage])
  }

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Workshop Preparation',
      children: (
        <div className='workshop-preparation-section'>
          <div className='create-request-section'>
            <CreateRequestForm
              handleChange={handleChange}
              handleDropdownChange={handleDropdownChange}
              generatePrompt={generatePrompt}
              requestLoader={requestLoader}
              industryTrends={industryTrends}
              configurationsteps={configurationsteps}
              agenda={agenda}
              promptValue={promptValue}
              requestValue={requestValue}
              roleName={roleName}
            />
          </div>
          <div className='assistant-bot-section'>
            <AssistantBotComponent
              question={question}
              setQuestion={setQuestion}
              roleName={roleName}
              chat={chat}
              loading={loading}
              sendChat={sendChat}
              sendRegenChatWithoutContext={sendRegenChatWithoutContext}
              sendRegenChatWithContext={sendRegenChatWithContext}
              sendElaborate={sendElaborate}
              sendSummarize={sendSummarize}
              currentActiveTab={currentActiveTab}
              handleModalOk={handleModalOk}
              showModal={showModal}
              setParentList={setParentList}
              parentList={parentList}
              setIsModalOpen={setIsModalOpen}
              setInputValue={setInputValue}
              inputValue={inputValue}
              showModalLoader={showModalLoader}
              isModalOpen={IsModalOpen}
              selectKey={SelectKey}
              defaultLabel={DefaultLabel}
              error={error}
              setCurrentActiveIndex={setCurrentActiveIndex}
            />
          </div>
        </div>
      ),
    },
    {
      key: '2',
      label: 'Block Diagram',
      children: currentActiveTab === '2' && <BlockDiagram />,
    },
  ]

  const onChange = (key: string) => {
    setCurrentActiveTab(key)
  }
  const [api, contextHolder] = notification.useNotification()

  const navigateToFileUpload = () => {
    setCurrentScreen('fileUpload')
  }

  const closePopup = () => {
    setShowWorkshopAssistantCancel(false)
  }

  return (
    <>
      <div style={{ height: '100vh' }}>
        <HeaderTileWorkshop
          projectNames={projectNames}
          roleName={roleName}
          setCurrentScreen={navigateToFileUpload}
        />
        <>
          <div
            style={{
              paddingLeft: '40px',
              overflow: 'hidden',
              height: 'calc(100% - 58px - 5.5rem)',
            }}
          >
            <ConfigProvider
              theme={{
                components: {
                  Tabs: {
                    inkBarColor: '#007CB0',
                    itemActiveColor: '#2E3238',
                    itemColor: '#59616C',
                    itemSelectedColor: '#2E3238',
                    itemHoverColor: '#2E3238',
                  },
                },
              }}
            >
              <Tabs
                items={items}
                onChange={onChange}
                activeKey={currentActiveTab}
                tabBarStyle={{
                  fontSize: '14px',
                  color: '#2E3238',
                  fontWeight: '600',
                  font: 'Open Sans',
                }}
              />
            </ConfigProvider>
          </div>
        </>
        <div>{contextHolder}</div>
        {showWorkshopAssistantCancel && (
          <div className='workshopAssistantCancel'>
            <Modal
              className='workshopAssistantCancel'
              style={{ width: '50%' }}
              title=''
              open={showWorkshopAssistantCancel}
              closable={true}
              onCancel={closePopup}
              footer={[
                <Button
                  onClick={() => {
                    setShowWorkshopAssistantCancel(false)
                  }}
                  key='cancel'
                  className='cancelWorkshopAssistantBtn'
                >
                  Cancel
                </Button>,
                <button
                  key='leaveWithoutSaving'
                  onClick={() => {
                    setCreateSnippet([])
                    setShowWorkshopAssistantCancel(false)
                    setCurrentScreen('fileUpload')
                    setAddPagesCount(0)
                  }}
                  className='cancelWorkshopAssistantBtn'
                >
                  Leave Without Saving
                </button>,
                <Button
                  key='saveAndLeave'
                  type='primary'
                  onClick={() => {
                    saveOnDone()
                  }}
                  className='goTobutton'
                >
                  Save and Leave
                </Button>,
              ]}
            >
              <h2>{cancelWorkshoppopupHeader}</h2>
              <div
                className='bottomLine'
                style={{ borderBottom: '1px solid #E8E8E8', margin: '10px 0' }}
              />
              <p className='popup-description'>{cancelWorkshopPopupDisclaimer}</p>
            </Modal>
          </div>
        )}
      </div>
    </>
  )
}

export default Prepare
