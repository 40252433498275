import { IPublicClientApplication } from '@azure/msal-browser'

export const logout = async (
  msalInstance: IPublicClientApplication,
  postLogoutRedirectUri = '/',
  mainWindowRedirectUri = window.location.href,
) => {
  try {
    console.log('Logging out...')
    await msalInstance.logoutPopup({
      postLogoutRedirectUri,
      mainWindowRedirectUri,
    })
    console.log('Logged out successfully.')
  } catch (err) {
    console.error('Error during logout:', err)
  }
}
