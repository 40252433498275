import './index.scss'
import logo from '../../assets/Logo.svg'
import bg from '../../assets/background2.png'
import chatLogo from '../../assets/chat1.png'
import generateIcon from '../../assets/generateIcon.svg'
import { useContext, useEffect, useState } from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import { useMsal } from '@azure/msal-react'
import { Button, Spin } from 'antd'
import getToken from '../../addTokenInterceptor'
import { API_STORE_WA_FILES } from '../../urls'
import { DataContext } from '../../DataContext'
import { getUploadHeaders } from '../../services/headerconstants'
import ToasterMessage from '../ToasterMessageComponent'
import { logout } from '../../utils/authUtils'

interface IHeaderComponent {
  projectNames: string
  roleName: any
  setCurrentScreen: () => void
}

const HeaderTileWorkshop: React.FC<IHeaderComponent> = (props: IHeaderComponent) => {
  const { instance } = useMsal()
  const API_URL = import.meta.env.VITE_KDD_API
  const { user_email, projectId, idToken, accessToken, user_name } = getToken()
  const [spinning, setSpinning] = useState(false)
  const [percent, setPercent] = useState(0)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const commonOutputScreenURL =
    import.meta.env.VITE_ASCEND_1_1_URL + `/?ascend_project_id=${projectId}`

  const showLoader = () => {
    setSpinning(true)
    let ptg = -10

    const interval = setInterval(() => {
      ptg += 5
      setPercent(ptg)

      if (ptg > 120) {
        clearInterval(interval)
        setSpinning(false)
        setPercent(0)
      }
    }, 200)
  }
  const {
    currentScreen,
    setCurrentScreen,
    setShowWorkshopAssistantCancel,
    createSnippetValue,
    uuidConst,
    setAddPagesCount,
  } = useContext(DataContext)
  const handleClick = () => {
    if (projectId !== null) {
      const commonPageUrl = new URL(import.meta.env.VITE_ASCEND_1_1_URL)
      commonPageUrl.searchParams.append('ascend_project_id', projectId)

      window.location.href = commonPageUrl.toString()
    }
  }

  const handleChatClick = () => {
    if (currentScreen === 'prepare') {
      if (projectId !== null) {
        setShowWorkshopAssistantCancel(true)
      }
    } else {
      if (projectId !== null) {
        const searchAndChatUrl = new URL(import.meta.env.VITE_ASCEND_CHAT_URL)
        searchAndChatUrl.searchParams.append('ascend_project_id', projectId)
        window.location.href = searchAndChatUrl.toString()
      }
    }
  }

  const handleGenAiClick = () => {
    window.location.href = commonOutputScreenURL
  }

  const handleEndSessionClick = async () => {
    const waFiles: any = {}
    let slides = 0
    showLoader()
    createSnippetValue.forEach((element: any) => {
      const waFileList =
        waFiles[element?.RecommendedTopic] && waFiles[element?.RecommendedTopic].length > 0
          ? waFiles[element?.RecommendedTopic]
          : []
      if (element?.RecommendedTopic !== element?.name) {
        const imageInfo = []
        if (element?.fileName !== '' && element?.imagePosition !== '') {
          const imageDetails = {
            upload_type: 'image_file',
            source: element?.fileName,
            image_position: element?.imagePosition,
          }
          imageInfo.push(imageDetails)
        } else {
          const imageDetails = {
            upload_type: '',
            source: '',
            image_position: '',
          }
          imageInfo.push(imageDetails)
        }
        if (element?.text.includes('|')) {
          const firstPipeIndex = element?.text.indexOf('|')
          const lastPipeIndex = element?.text.lastIndexOf('|')
          const tableText = element?.text.substring(firstPipeIndex, lastPipeIndex + 1)
          const tabledataObj = {
            tabledata: [tableText],
          }
          waFileList.push({
            table: [tabledataObj],
            text: [],
            image_details: imageInfo,
          })
        } else {
          const textList = [element?.text]
          waFileList.push({
            text: textList,
            table: [],
            image_details: imageInfo,
          })
        }
        slides++
      }
      waFiles[element?.RecommendedTopic] = waFileList
    })
    setAddPagesCount(slides)
    try {
      const data = {
        uuid: uuidConst,
        projectid: projectId,
        createdby: user_name,
        wa_file: waFiles,
      }
      const response = await fetch(`${API_URL}/${API_STORE_WA_FILES}`, {
        method: 'POST',
        headers: getUploadHeaders(accessToken, idToken, projectId),
        body: JSON.stringify(data),
      })
      if (!response?.ok) {
        const message = `An error has occurred: ${response.statusText}`
        throw new Error(message)
      } else {
        setCurrentScreen('fileUpload')
      }
    } catch (err) {
      if (err instanceof Error) {
        setErrorMessage(err.message)
      }
    }
    setCurrentScreen('fileUpload')
  }

  const [userEmail, setUserEmail] = useState('')
  const getUserEmail = async () => {
    setUserEmail(user_email)
  }

  const downloadDocumentation = (): string =>
    'https://amedeloitte.sharepoint.com/:w:/r/sites/GenerativeAI-Oracle/_layouts/15/download.aspx?SourceURL=Shared%20Documents/04.%20Training/Ascend%202.0/Ascend%20GenAI%20User%20Manual.pdf'
  useEffect(() => {
    getUserEmail()
  })

  return (
    <>
      <div style={{ width: '100%', zIndex: '999' }}>
        <div className='header'>
          <div className='header-left'>
            <img src={logo} alt='deloitte logo' className='header-logo' />
            <div className='vertical-line' />
            {/* <a href={handleGenAiClick()}>
              <span className='header-text'>
                Gen AI<sup>TM</sup>
              </span>
            </a> */}
            <Button type='text' className='header-button' onClick={() => handleGenAiClick()}>
              <span className='header-text'>
                Gen AI<sup>TM</sup>
              </span>
            </Button>
          </div>
          <div className='header-right'>
            <div className='float-right'>
              <div className='email-text'>
                <a href={downloadDocumentation()} className='content download-user-guide'>
                  <DownloadOutlined className='download-icon' /> Download User Guide
                </a>
              </div>
              <div className='email-text'>
                <div className='content'>{userEmail}</div>
              </div>
              <Button className='sign-button' type='primary' onClick={() => logout(instance)}>
                Sign Out
              </Button>
            </div>
          </div>
        </div>
        <div className='parent'>
          <img src={bg} className='image1' />
          {currentScreen === 'prepare' && (
            <div className='generate-ppt-button done-button' onClick={handleEndSessionClick}>
              {currentScreen !== 'prepare' && (
                <img src={generateIcon} alt='' className='chat-icon generate-icon' />
              )}
              Done
            </div>
          )}
          {currentScreen !== 'prepare' && (
            <div className='search-and-chat-description'>
              Search for documents, design decisions, project status and more
            </div>
          )}
          <div
            className={currentScreen === 'prepare' ? 'cancel-button' : 'chat-button'}
            onClick={handleChatClick}
          >
            {currentScreen !== 'prepare' && <img src={chatLogo} alt='' className='chat-icon' />}
            {currentScreen === 'prepare' ? 'Cancel' : 'Search and Chat'}
          </div>
          <span className='back-arrow' onClick={handleClick}>
            &lt;
          </span>
          <span className='seperator'>|</span>
          <span className='workshop-heading'>{props.projectNames}</span>
        </div>
        <Spin spinning={spinning} percent={percent} fullscreen />
      </div>
      <ToasterMessage errorMessage={errorMessage} count={0}/>
    </>
  )
}

export default HeaderTileWorkshop
