import React, { useCallback, useEffect, useState } from 'react'
import './App.scss'
import { TRPCProvider } from './Components/TRPCProvider'

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication,
  useMsal,
} from '@azure/msal-react'
import { InteractionStatus, InteractionType } from '@azure/msal-browser'
import { DataProvider } from './DataContext'
import UserAuthorization from './Components/AuthComponent'
const INACTIVITY_TIMEOUT = 60 * 60 * 1000

const MainContent = () => {
  const [ascendProjectId, setAscendProjectId] = useState<string | null>(null)
  const [lastActivity, setLastActivity] = useState(Date.now())
  const { instance, inProgress } = useMsal()

  // Handle user activity
  const resetTimer = useCallback(() => {
    setLastActivity(Date.now())
  }, [])

  // Sign out the user
  const handleSignOut = useCallback(
    (interval: any) => {
      instance.logoutRedirect()
      resetTimer()
    },
    [instance],
  )

  useEffect(() => {
    // Parse the current URL's search parameters
    const params = new URLSearchParams(window.location.search)

    // Get the value of the 'ascend_project_id' parameter
    let tempProjectId = params.get('ascend_project_id')
    if (tempProjectId === null) {
      tempProjectId = ''
    }
    setAscendProjectId(tempProjectId)
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (Date.now() - lastActivity > INACTIVITY_TIMEOUT) {
        handleSignOut(interval)
      }
    }, 1000) // Every second check
    return () => clearInterval(interval)
  }, [lastActivity, handleSignOut])

  // Event listeners for detecting user activity
  useEffect(() => {
    window.addEventListener('mousemove', resetTimer)
    window.addEventListener('keypress', resetTimer)
    window.addEventListener('click', resetTimer)

    return () => {
      window.removeEventListener('mousemove', resetTimer)
      window.removeEventListener('keypress', resetTimer)
      window.removeEventListener('click', resetTimer)
    }
  }, [resetTimer])

  // Avoid signing out during Auth process
  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      resetTimer()
    }
  }, [inProgress, resetTimer])

  if (inProgress === InteractionStatus.Startup || inProgress === InteractionStatus.HandleRedirect) {
    return <UserAuthorization /> // Prevents blank screen during MSAL initialization
  }

  return (
    <DataProvider>
      <div className='App'>
        {ascendProjectId ? (
          <AuthenticatedTemplate>
            <TRPCProvider>
              <UserAuthorization />
            </TRPCProvider>
          </AuthenticatedTemplate>
        ) : (
          <>
            <UnauthenticatedTemplate />
            <UserAuthorization />
          </>
        )}
      </div>
    </DataProvider>
  )
}

function App() {
  const { instance, inProgress } = useMsal()

  useEffect(() => {
    if (inProgress === InteractionStatus.None && instance.getAllAccounts().length === 0) {
      instance.loginRedirect()
    }
  }, [instance, inProgress])
  return <MainContent />
}

export default App
