import { API_POST_CREATE_PROMPT } from '../urls'
import { getHeaders } from './headerconstants'

const makePrompApiCall = async (
  inputTopicValue: string,
  inputTopic: string,
  idToken: string,
  accessToken: string,
  projectId: string,
  user_email: string,
  roleName: string,
) => {
  const response: Response = await fetch(API_POST_CREATE_PROMPT, {
    method: 'POST',
    headers: getHeaders(accessToken, idToken, projectId),
    body: JSON.stringify({
      RecommendedTopics: inputTopicValue,
      inputValue: inputTopic,
      userEmail: user_email,
      workshopName: roleName,
    }),
  })
  let loading = true
  if (response?.body) {
    const reader = response?.body.getReader()
    const decoder = new TextDecoder()
    let tempValue = ''
    while (loading) {
      const { value, done } = await reader.read()
      const decodedChunk = decoder.decode(value, { stream: true })
      if (value) {
        tempValue += decodedChunk
      }
      if (done) {
        loading = false
        return JSON.parse(tempValue)?.result?.data
      }
    }
  }
}

const getRandomSecureNumber = (min: number, max: number) => {
  const randomBuffer = new Uint32Array(1)
  window.crypto.getRandomValues(randomBuffer)
  const range = max - min + 1
  const maxRange = 0xffffffff

  const randomNumber = Math.floor((randomBuffer[0] / (maxRange + 1)) * range) + min
  return randomNumber
}

export { makePrompApiCall, getRandomSecureNumber }
