export const API_GET_OUTPUTID = '/trpc/end.create'
export const API_ADD_OUTPUTFILE = '/trpc/end.addFiles'
export const API_GET_PROJECTNAME = '/trpc/userinfo.getProjectName'
export const API_POST_STREAM = '/api/chat/stream'
export const API_POST_CREATE_PROMPT = '/trpc/prompt.create'
export const API_GET_SNIPPET_RECORDS = '/trpc/session.get,session.sessionSnippets?batch=1&input='
export const API_POST_CREATE_SNIPPET = '/trpc/session.createSnippet'
export const API_POST_CREATE_SESSION = '/trpc/session.create'
export const API_POST_PPT = '/trpc/ppt.exportSessionSnippet'
export const API_GENERATE_SASURLS = 'WorkshopAst/generate_sas_url/'
export const API_STORE_WA_FILES = 'WorkshopAst/store-wa-file/'
export const API_UPLOAD_TO_SHAREPOINT = 'WorkshopAst/celery/upload_to_sharepoint/'
export const API_MALICIOUS_FILE = 'WorkshopAst/check_malicious/'
