import React, { useContext, useEffect, useState } from 'react'
import BreadCrumb from '../../Components/BreadCrumb'
import { DataContext } from '../../DataContext'
import './index.scss'
import {
  GetProp,
  NotificationArgsProps,
  Progress,
  Spin,
  Upload,
  UploadProps,
  notification,
} from 'antd'
import getToken from '../../addTokenInterceptor'
import { API_GENERATE_SASURLS } from '../../urls'
import Footer from '../../Components/Footer'
import divider from '../../assets/divider.svg'
import cross from '../../assets/cross.svg'
import check from '../../assets/checkIcon.svg'
import { LoadingOutlined, WarningOutlined } from '@ant-design/icons'
import HeaderTileWorkshop from '../../Components/HeaderTileWorkshop'
import { getProjectNames } from '../../services/apiService'
import {
  checkFileNameExists,
  checkForAllFilesSizeValidation,
  checkForFileTypes,
  checkForFileValidation,
  checkForMaximumFileSize,
  checkForMultipleDuplicates,
  checkForMultipleFileValidation,
  checkForMultipleIcsFile,
  checkMaliciousFilesAPI,
  checkMinimumFileSizeValidation,
  isValidFileExtension,
  readToSharepointApi,
  uploadFilesToSasUrls,
} from '../../services/fileUploadService'
import KnbFileUploadComponent from '../../Components/KnbFileUploadComponent'
import OptionalFileList from '../../Components/OptionalFileListComponent'
import WorkshopAssistantSection from '../../Components/AddPagesComponent'
import GenerateOutputButton from '../../Components/GenerateOutputButton'
import { maliciousFileWarning, maximumFileSizeWarning, minimumFileSizeWarning } from './constant'
import { getUploadHeaders } from '../../services/headerconstants'
import ToasterMessage from '../../Components/ToasterMessageComponent'

const FileUpload: React.FC = () => {
  const {
    existingFilesData,
    workshopNameData,
    promptData,
    additionalPromptData,
    outputFileNameData,
    uploadAllFilesObjectList,
    setUploadAllFilesObjectList,
    uploadAllFileList,
    setAllUploadFileList,
    kbFilesData,
    setKbFileData,
    slidesAddedData,
    setCurrentScreen,
    currentScreen,
    sections,
    commonOutputID,
    setCommonOutputId,
    uuidConst,
    addPagesCount,
    createSnippetValue,
    maliciousFiles,
    setMaliciousFiles,
  } = useContext(DataContext)
  const [projectNames, setProjectNames] = useState('')
  const [loading, setLoading] = useState(false)
  const [roleName, setroleName] = useState('')
  const [removeFileClickedList, setRemoveFileClickedList] = useState<any>([])
  const [kbFiles, setKbfiles] = useState([])
  const [uploadingActive, setUploadingActive] = useState(false)
  const [notify, contextHolder_2] = notification.useNotification()
  const [currentFilesUploading, setCurrentFilesUploading] = useState<IFileUploadingObj[]>([])
  // const [uploadAllFileList, setAllUploadFileList] = useState<IFileUploadObj[]>([])
  const [addPagesData, setAddPagesData] = useState<any[]>([])
  const [uploadKey, setUploadKey] = useState<number>(0)
  const [fileList, setFileList] = useState<any[]>([])
  const [uploadIntervals, setUploadIntervals] = useState(new Map())
  const [rowId, setRowId] = useState(0)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [count, setCount] = useState(0)
  const API_URL = import.meta.env.VITE_KDD_API
  interface IFileUploadObj {
    name: string
    size: string
    active: boolean
    percent: any
  }
  interface IFileUploadingObj {
    name: string
    size: string
    active: boolean
    percent: any
  }
  const promptValue: string = localStorage.getItem('promptValue')!
  const { accessToken, idToken, projectId, user_email } = getToken()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const showModal = () => {
    setIsModalOpen(true)
  }
  const myProps = {
    promptvalue: promptValue,
    kbFiles: kbFiles,
    projectid: projectId,
    addfilescallback: (files: any) => {
      // Your callback logic here
      setKbfiles(files)
      setKbFileData(files)
    },
    idtoken: idToken,
    accesstoken: accessToken,
  }

  const checkPayloadFiles = async () => {
    const ExFiles = []
    if (existingFilesData.length !== 0) {
      ExFiles.push({ filename: existingFilesData?.name })
    }
    const InpFiles = []
    for (let i = 0; i < uploadAllFileList.length; i++) {
      InpFiles.push({ filename: uploadAllFileList[i]?.name })
    }
    const wkbFiles = []
    for (let i = 0; i < kbFiles.length; i++) {
      wkbFiles.push({ filename: kbFiles[i] })
    }
    const waFiles: any = {}
    slidesAddedData.forEach((element: any) => {
      if (element?.text.includes('|'))
        waFiles[element?.RecommendedTopic] = {
          table: element?.text,
          text: [],
          image: [],
        }
      else
        waFiles[element?.RecommendedTopic] = {
          text: element?.text,
          table: [],
          image: [],
        }
    })
    return { ExFiles, InpFiles, wkbFiles, waFiles }
  }
  const generateOutput = async () => {
    setLoading(true)
    const { ExFiles, InpFiles, wkbFiles } = await checkPayloadFiles()

    // Filter out files that have been removed by the user
    const filteredUploadFileList = uploadAllFilesObjectList.filter(
      (file) => !removeFileClickedList.some((removedFile) => removedFile.name === file.name),
    )
    if (
      // Commenting this as this not mandoatory in the other usecases as mentioned by Diksha
      // checkForMultipleFileValidation(uploadAllFileList) &&
      !checkForMultipleDuplicates(uploadAllFileList)
    ) {
      try {
        const uniqId = uuidConst
        const data = {
          uniq_id: uniqId,
          job_id: 0,
          project_id: projectId,
          workshop_name: workshopNameData,
          text_prompt: promptData,
          aiding_prompt: additionalPromptData,
          sections: sections,
          existing_files: ExFiles,
          output_filename: outputFileNameData,
          wkb_file: wkbFiles,
          input_files: InpFiles,
          wa_chat_images: [],
        }
        const response = await fetch(`${API_URL}/${API_GENERATE_SASURLS}`, {
          method: 'POST',
          headers: getUploadHeaders(accessToken, idToken, projectId),
          body: JSON.stringify(data),
        })
        if (!response?.ok) {
          setLoading(false)
          const message = `An error has occurred: ${response.statusText}`
          throw new Error(message)
        } else {
          const result = await response.json()
          // await setCommonOutputId(result?.commonoutputid)
          // const tempRowId = await result.row_id
          // setRowId(tempRowId)

          // Pass the filtered file list to upload
          if (existingFilesData.length !== 0 || InpFiles.length !== 0) {
            await uploadFilesToSasUrls(result, filteredUploadFileList, existingFilesData)
          }

          // showModal()
          await checkMaliciousFiles(result, uniqId)
        }
      } catch (err: any) {
        if (err instanceof Error) {
          setErrorMessage(err.message)
        }
      }
    }
  }

  const checkMaliciousFiles = async (result: any, uniqId: string) => {
    const { InpFiles } = await checkPayloadFiles()
    try {
      const filesData = {
        uuid: uniqId,
        input_files: InpFiles,
        input_file_category: 'inputfile',
      }
      const response = await checkMaliciousFilesAPI(idToken, accessToken, projectId, filesData)
      if (response.detail) {
        const { malicious_files, detail } = response

        if (malicious_files && malicious_files.length > 0) {
          await setMaliciousFiles(malicious_files)
          setErrorMessage(detail)
          setLoading(false)
          return
        }
      } else {
        await setCommonOutputId(response?.common_output_id)
        await setMaliciousFiles([])
        // Proceed with other logic
        const tempRowId = await response?.row_id
        setRowId(tempRowId)
        showModal()
        setLoading(false)
        await uploadToSharepoint(response, uniqId, tempRowId)
      }
    } catch (err) {
      console.error('Error uploading files:', err)
      setErrorMessage('An error occurred during upload. Please try again.')
      setLoading(false)
    }
  }

  const uploadToSharepoint = async (result: any, uniqId: string, tempRowId: number) => {
    const { ExFiles, InpFiles, wkbFiles } = await checkPayloadFiles()

    try {
      const data = {
        uniq_id: uniqId,
        job_id: 0,
        project_id: projectId,
        workshop_name: workshopNameData,
        text_prompt: promptData,
        aiding_prompt: additionalPromptData,
        sections: sections,
        existing_files: ExFiles,
        output_filename: outputFileNameData,
        wkb_file: wkbFiles,
        input_files: InpFiles,
        sas_urls: {},
        common_output_id: result?.common_output_id,
        row_id: String(tempRowId),
      }
      // const response = await readToSharepointApi(idToken, accessToken, projectId, data)
      // const {detail, malicious_files} = response
      try {
        await readToSharepointApi(idToken, accessToken, projectId, data)
        // const response = {
        //   detail: 'The uploaded file doesnot contain malicious content and can be processed.',
        //   malicious_files: ['IMG_2132.jpeg', 'IMG_2133.jpeg'],
        // }
      } catch (error) {
        console.error('Error uploading files:', error)
        setErrorMessage('An error occurred during upload. Please try again.')
      }
      // await uploadTextApi(idToken, accessToken, projectId, newData)
      // await uploadTableApi(idToken, accessToken, projectId, newData)
    } catch (err) {
      if (err instanceof Error) {
        setErrorMessage(err.message)
      }
    }
  }

  useEffect(() => {
    if (projectId) {
      const fetchProjectNames = async () => {
        try {
          const names = await getProjectNames(accessToken, idToken, projectId)
          setProjectNames(names)
        } catch (err) {
          if (err instanceof Error) {
            setErrorMessage(err.message)
          }
        }
      }
      fetchProjectNames()
    }
  }, [projectId, user_email])
  useEffect(() => {
    window.scrollTo(0, 0)
    setKbfiles(kbFilesData)
    setAllUploadFileList(uploadAllFileList)
    setCurrentFilesUploading(uploadAllFileList)
    if (slidesAddedData.length > 0) {
      setAddPagesData(slidesAddedData)
    }
  }, [])
  const handleAddPage = () => {
    setCurrentScreen('prepare')
  }

  const simulateUpload = (file: any) => {
    let progress = 0

    const interval = setInterval(() => {
      progress += 10

      setCurrentFilesUploading((prevFiles) =>
        prevFiles.map((fileObj) => {
          if (fileObj.name === file.name) {
            return { ...fileObj, percent: progress } // Create a new object for the file with updated progress
          }
          return fileObj // Return the other files as-is
        }),
      )

      if (progress >= 100) {
        clearInterval(interval)
        setUploadIntervals((prevIntervals) => {
          const updatedIntervals = new Map(prevIntervals)
          updatedIntervals.delete(file.name)
          return updatedIntervals
        })
        setAllUploadFileList((prevFiles) => [
          ...prevFiles,
          {
            name: file.name,
            size: file.size,
            active: true,
            percent: 100,
          },
        ])
        setUploadAllFilesObjectList((prevList) => [...prevList, file])
        setUploadingActive(false)
      }
    }, 500) // Simulate progress every 500ms
    setUploadIntervals((prevIntervals) => new Map(prevIntervals.set(file.name, interval)))
  }

  const OnFileUpload = async (info: IFileUploadObj) => {
    const accept = '.vtt,.txt,.ics,.m4a,.mp4,.xls,.ppt,.msg,.eml,.png,.jpeg,.docx,.pdf,.pptx,.xlsx'
    if (maliciousFiles.includes(info.name)) {
      setErrorMessage(
        `File you are trying to upload is malicious and has been blocked - ${info.name}`,
      )
      setCount(count + 1)
      return false
    }
    if (checkFileNameExists(info, uploadAllFileList)) {
      setErrorMessage(
        'Phew! we found more file with the same name. Kindly try again with a different file',
      )
      setCount(count + 1)
    }
    if (checkForMultipleIcsFile(info.name, uploadAllFileList)) {
      setErrorMessage(
        `Phew! we found more than one .ics uploaded. Kindly keep only one .ics to move ahead`,
      )
      setCount(count + 1)
      return
    }
    if (!checkForFileValidation(info.name, uploadAllFileList)) {
      setErrorMessage(
        'Phew! Please add an ics file along with the vtt or txt or mp4 or m4a files to proceed',
      )
      setCount(count + 1)
    }
    if (!isValidFileExtension(info.name, accept)) {
      setErrorMessage('Please check if the file extension is valid')
      setCount(count + 1)
      return
    }
    if (
      checkForFileTypes(info) &&
      !checkFileNameExists(info, uploadAllFileList) &&
      !checkForMultipleIcsFile(info.name, uploadAllFileList) &&
      checkForFileValidation(info.name, uploadAllFileList)
    ) {
      const updatedList = [...currentFilesUploading]
      setUploadingActive(true)
      const index = updatedList.findIndex((file) => file.name === info.name)

      // If the file is already being uploaded, update its progress
      if (index !== -1) {
        updatedList[index].percent = 0
        setCurrentFilesUploading([...updatedList])
      } else {
        // If it's a new file, add it to the currentFilesUploading array
        if (info.size && !checkPushed(info.name)) {
          setCurrentFilesUploading((prevFiles) => [
            ...prevFiles,
            {
              name: info.name,
              size: info.size,
              active: true,
              percent: 0,
            },
          ])

          // Simulate the upload for the new file
          simulateUpload(info)
        }
      }
    }
  }

  const checkPushed = (fileName: string) => {
    for (let i = 0; i < currentFilesUploading.length; i++) {
      if (currentFilesUploading[i]?.name === fileName) return true
    }
  }
  const navigateToFileUpload = () => {
    setCurrentScreen('fileUpload')
  }

  const checkValidation = () => {
    if (
      (uploadAllFileList.length > 0 || kbFiles.length > 0 || addPagesCount) &&
      !uploadAllFileList.includes(...maliciousFiles)
    ) {
      if (uploadAllFileList.length > 0 || kbFiles.length > 0) {
        if (
          !checkForMultipleIcsFile('', uploadAllFileList) &&
          checkForAllFilesSizeValidation(uploadAllFileList) &&
          // Commenting this as this not mandoatory in the other usecases as mentioned by Diksha
          // checkForMultipleFileValidation(uploadAllFileList) &&
          !uploadingActive
        ) {
          return true
        }
      } else {
        return true
      }
    }
    return false
  }
  const onRemoveFile = (fileObj) => {
    setUploadIntervals((prevIntervals) => {
      const interval = prevIntervals.get(fileObj.name)
      if (interval) {
        clearInterval(interval)
      }

      // Remove the file from the map of intervals
      const updatedIntervals = new Map(prevIntervals)
      updatedIntervals.delete(fileObj.name)
      setUploadIntervals(updatedIntervals)

      return updatedIntervals
    })
    setCurrentFilesUploading((prevItems) => {
      const updatedItems = prevItems.filter((item) => item.name !== fileObj.name)
      return updatedItems
    })

    setAllUploadFileList((prevFiles) => prevFiles.filter((file) => file.name !== fileObj.name))
    // Add removed file to the list
    setRemoveFileClickedList((prevList) => {
      const updatedList = [...prevList, fileObj]
      return updatedList
    })

    // // Added malicious file to list after upload fails so same file cannot be uploaded
    // setMaliciousFileList((prevList) => {
    //   const updatedList = [...prevList, fileObj]
    //   return updatedList
    // })
    // Also remove the file from the upload list
    setUploadingActive(false)

    // Reset the upload key (or another trigger if needed) to ensure new files are accepted after removal
    setUploadKey((prevKey) => prevKey + 1)
  }

  useEffect(() => {
    // After removing a file, ensure both lists (current and all) are recalculated correctly
    // const updatedFiles = uploadAllFileList.filter(
    //   (file) => !removeFileClickedList.some((removedFile) => removedFile.name === file.name),
    // )
    // setCurrentFilesUploading(updatedFiles)
  }, [currentFilesUploading, uploadAllFileList])
  const Uploadprops: UploadProps = {
    action: undefined,
    beforeUpload: (file) => {
      OnFileUpload(file)
      return false
    },
    fileList,
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  }

  const checkStrokeColor = (fileObj: IFileUploadObj) => {
    let colorCode
    if (
      (!checkForMaximumFileSize(fileObj) && fileObj?.percent === 100) ||
      (maliciousFiles.includes(fileObj.name) && fileObj?.percent === 100)
    )
      colorCode = '#ff5a43'
    else if (!checkMinimumFileSizeValidation(fileObj) && fileObj?.percent === 100)
      colorCode = '#ed8b00'
    else colorCode = fileObj?.percent === 100 ? '#007CB0' : '#59616C'
    return colorCode
  }

  const generateWarning = (fileObj: IFileUploadObj) => {
    if (!checkForMaximumFileSize(fileObj)) return maximumFileSizeWarning
    else if (!checkMinimumFileSizeValidation(fileObj)) return minimumFileSizeWarning
    else if (maliciousFiles.includes(fileObj.name)) return maliciousFileWarning
    else return ''
  }

  return (
    <>
      <div>
        <HeaderTileWorkshop
          projectNames={projectNames}
          roleName={roleName}
          setCurrentScreen={navigateToFileUpload}
        />
        <BreadCrumb setCurrentScreen={setCurrentScreen} currentScreen={currentScreen} />
        {contextHolder_2}
        <div className='workshopDetails-landing'>
          <div className='workshop-details-header'>Files Upload and Output Generation </div>
          <div className='workshop-details-subheading'>
            Upload files or select from the Deloitte Knowledge Base of Workshop Decks to Generate
            Output.
          </div>
          <div className='workshop-details-container'>
            <div className='file-upload-output-generation-container'>
              <KnbFileUploadComponent kbFiles={kbFiles} setKbFiles={setKbfiles} myProps={myProps} />
              <div className='upload-file-multiple-container'>
                <div className='upload-multiple-file-headers'>Upload files</div>
                <div className='upload-multiple-file-content'>
                  <div className='upload-multiple-file-content-left'>
                    <OptionalFileList />
                    <div className='upload-required-files'>Upload Required files</div>
                    <div>
                      <Upload.Dragger
                        key={uploadKey}
                        action={undefined}
                        className='upload-box-dragger'
                        accept='.vtt,.txt,.ics,.m4a,.mp4,.xls,.ppt,.msg,.eml,.png,.jpeg,.docx,.pdf,.pptx,.xlsx'
                        {...Uploadprops}
                        showUploadList={false}
                        multiple={true}
                      >
                        <div className='drag-and-drop-text'>
                          Drag & Drop file(s) here or <span className='browse-button'>Browse</span>
                        </div>
                      </Upload.Dragger>
                    </div>
                  </div>
                  {uploadAllFileList.length > 0 || currentFilesUploading.length > 0 ? (
                    <div className='progress-bars-container'>
                      {currentFilesUploading &&
                        currentFilesUploading.map((fileObj: any, index: number) => (
                          <div key={index}>
                            <div className='progress-bar-section'>
                              {checkForMaximumFileSize(fileObj) &&
                                checkMinimumFileSizeValidation(fileObj) &&
                                !maliciousFiles.includes(fileObj.name) &&
                                fileObj?.percent === 100 && (
                                  <span className='checkIcon'>
                                    <img src={check} />
                                  </span>
                                )}
                              {!checkForMaximumFileSize(fileObj) && fileObj?.percent === 100 && (
                                <span className='checkIcon'>
                                  <WarningOutlined style={{ color: '#ff5a43' }} />
                                </span>
                              )}
                              {!checkMinimumFileSizeValidation(fileObj) &&
                                fileObj?.percent === 100 && (
                                  <span className='checkIcon'>
                                    <WarningOutlined style={{ color: '#ed8b00' }} />
                                  </span>
                                )}
                              {maliciousFiles.includes(fileObj.name) &&
                                fileObj?.percent === 100 && (
                                  <span className='checkIcon'>
                                    <WarningOutlined style={{ color: '#ff5a43' }} />
                                  </span>
                                )}
                              {fileObj?.name}
                              <span className='size-progress'>
                                {`${(fileObj?.size / 1024 / 1024).toFixed(2)} MB`}
                                <img src={divider} className='divider-icon' />
                                <img
                                  src={cross}
                                  className='cross-icon'
                                  onClick={async () => {
                                    onRemoveFile(fileObj)
                                  }}
                                />
                              </span>
                            </div>
                            <Progress
                              percent={fileObj?.percent}
                              strokeLinecap='butt'
                              size={['', 4]}
                              showInfo={false}
                              strokeColor={checkStrokeColor(fileObj)}
                            />
                            {(checkForMaximumFileSize(fileObj) ||
                              checkMinimumFileSizeValidation(fileObj)) &&
                              fileObj?.percent === 100 && (
                                <div className='maximumWarning'>{generateWarning(fileObj)}</div>
                              )}
                            {!maliciousFiles.includes(fileObj.name) && fileObj?.percent === 100 && (
                              <div className='minimumWarning'>{generateWarning(fileObj)}</div>
                            )}
                            {/* {!maliciousFiles.includes(fileObj.name) && fileObj?.percent === 100 && (
                              <div className='minimumWarning'>{generateWarning(fileObj)}</div>
                            )} */}
                          </div>
                        ))}
                    </div>
                  ) : (
                    <div className='knowledge-base-content-right'>
                      <div className='no-files-selected-text'>There are no Uploaded Files</div>
                      <div className='open-text'>
                        Please click Browse or use Drag & Drop area to upload files
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <WorkshopAssistantSection
                addPagesCount={addPagesCount}
                createSnippetValue={createSnippetValue}
                uploadingActive={uploadingActive}
                setCurrentScreen={setCurrentScreen}
                handleAddPage={handleAddPage}
              />
            </div>
            <GenerateOutputButton
              checkValidation={checkValidation}
              generateOutput={generateOutput}
              projectId={projectId}
              isModalOpen={isModalOpen}
              uploadingActive={uploadingActive}
            />
            {loading && (
              <div className='loading-generate-output'>
                <Spin
                  indicator={<LoadingOutlined spin />}
                  style={{ fontSize: 60, color: '#007CB0' }}
                />
                <div className='loading-text'>Loading...</div>
              </div>
            )}
          </div>
          <Footer />
        </div>
      </div>
      <ToasterMessage errorMessage={errorMessage} count={count} />
    </>
  )
}

export default FileUpload
