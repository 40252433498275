/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMsal } from '@azure/msal-react'
import { useEffect, useState } from 'react'
import { InteractionStatus } from '@azure/msal-browser'

const getToken = () => {
  const { instance, inProgress } = useMsal()
  const [tokenData, setTokenData] = useState({
    accessToken: '',
    idToken: '',
    projectId: '',
    user_email: '',
    user_name: '',
  })

  useEffect(() => {
    const fetchToken = async () => {
      // Ensure MSAL is fully initialized before proceeding
      if (inProgress !== InteractionStatus.None) return

      const [account] = instance.getAllAccounts()
      if (account) {
        const accessTokenRequest = {
          scopes: ['user.read'],
          account: account,
        }

        const params = new URLSearchParams(window.location.search)
        const projectId = params.get('ascend_project_id') || ''

        try {
          const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest)
          setTokenData({
            accessToken: accessTokenResponse.accessToken,
            idToken: accessTokenResponse.idToken,
            projectId: projectId,
            user_email: accessTokenResponse.account.username,
            user_name: accessTokenResponse.account.name!,
          })
        } catch (error) {
          console.error('Failed to acquire token silently:', error)
          instance.loginRedirect() // Initiate login properly
        }
      } else {
        instance.loginRedirect()
      }
    }

    fetchToken()
  }, [instance, inProgress]) // Only run when `inProgress` changes

  return tokenData
}

export default getToken
