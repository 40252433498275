import { SetStateAction, useContext, useEffect, useState } from 'react'
import './index.scss'

import { Button, Checkbox, Divider, Input, Modal, Tooltip, message } from 'antd'
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  HolderOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import getToken from '../../addTokenInterceptor'
import { DataContext } from '../../DataContext'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { getDefaultHeaders } from '../../services/headerconstants'
import ToasterMessage from '../ToasterMessageComponent'

interface IAddSectionsProps {
  addSectionCallback: (checkedList: string[]) => void
}

const AddSections: React.FC<IAddSectionsProps> = (props: IAddSectionsProps) => {
  // const plainOptions = [
  //   'Agenda',
  //   'Introduction- Workshop Rules',
  //   'Industry Trends',
  //   'Summary',
  //   'Appendix',
  // ]
  const {
    sections,
    setSections,
    plainOptionSections,
    setPlainOptionSections,
    customOptionSections,
    setCustomOptionSections,
  } = useContext(DataContext)
  const [plainOptions, setPlainOptions] = useState<string[]>([])

  const [customOptions, setCustomOptions] = useState<string[]>([])
  const [checkedList, setCheckedList] = useState<string[]>([])
  const [tempCheckedList, setTempCheckedList] = useState<string[]>([])
  let newCheckedList: string[] | ((prevState: string[]) => string[]) = []

  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [addSectionField, setAddSectionField] = useState(false)
  const [customSectionValue, setCustomSectionValue] = useState('')
  const [isEditable, setIsEditable] = useState<string[]>([])
  const [messageApi, contextHolder] = message.useMessage()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { accessToken, idToken, projectId } = getToken()
  const key = 'updatable'
  const API_URL: string = import.meta.env.VITE_KDD_API

  const undoDelete = (option: string) => {
    if (plainOptions.includes(option)) {
      setPlainOptions(plainOptions)
    }
    if (customOptions.includes(option)) {
      setCustomOptions(customOptions)
    } else {
      setPlainOptions([...plainOptions, option])
      setCustomOptions([...customOptions, option])
    }
  }

  useEffect(() => {
    if (tempCheckedList.length == 0) {
      setCheckedList(sections)
      setTempCheckedList(sections)
      setPlainOptions(plainOptionSections)
      setCustomOptions(customOptionSections)
    }
  }, [])

  const success = (option: string) => {
    const closeIcon = (
      <CloseOutlined
        style={{ color: '#fff', marginLeft: '10px' }}
        onClick={() => messageApi.destroy(key)}
      />
    )

    messageApi.open({
      key,
      // type: 'loading',
      content: (
        <span className='message-span'>
          {/* <div className='loader' /> */}
          <strong>{option}</strong> section has been deleted
          <Button type='text' className='undo-btn' onClick={() => undoDelete(option)}>
            Undo
          </Button>
          {closeIcon}
        </span>
      ),
      className: 'message',
      duration: 1.5,
    })
  }

  const failure = (option: string) => {
    const closeIcon = (
      <CloseOutlined
        style={{ color: '#fff', marginLeft: '10px' }}
        onClick={() => messageApi.destroy(key)}
      />
    )

    messageApi.open({
      key,
      // type: 'loading',
      content: (
        <span className='message-span'>
          {/* <div className='loader' /> */}
          <strong>{option}</strong> section is already present in list
          {closeIcon}
        </span>
      ),
      className: 'message',
      duration: 1.5,
    })
  }
  const showLoading = async () => {
    setOpen(true)
    setLoading(true)
    try {
      const response = await fetch(`${API_URL}/WorkshopAst/sections-list`, {
        method: 'GET',
        headers: getDefaultHeaders(accessToken, idToken, projectId),
      })
      if (!response?.ok) {
        const message = `An error has occurred: ${response.statusText}`
        throw new Error(message)
      } else {
        const result = await response.json()

        if (plainOptions.length === 0) {
          setPlainOptions(result.sections)
        }
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      if (error instanceof Error) {
        setErrorMessage(error.message)
      }
    }
  }

  const handleCancel = () => {
    setTempCheckedList(checkedList)
    setOpen(false)
    setErrorMessage('')
  }

  const handleModalOk = () => {
    newCheckedList = plainOptions.filter((option) => tempCheckedList.includes(option))
    props.addSectionCallback(newCheckedList)

    setSections(newCheckedList)
    setPlainOptionSections(plainOptions)
    setCustomOptionSections(customOptions)
    // localStorage.setItem('Plain Options', JSON.stringify(plainOptions))
    // localStorage.setItem('Custom Options', JSON.stringify(customOptions))
    setCheckedList(tempCheckedList)
    setErrorMessage('')
    setOpen(false)
  }

  const getInputValue = (e: { target: { value: SetStateAction<string> } }) => {
    if (e.target.value != '') {
      setCustomSectionValue(e.target.value)
    }
  }

  const selectAll = () => {
    setTempCheckedList(plainOptions)
  }
  const unselectAll = () => {
    setTempCheckedList([])
  }
  const cancelCustomSection = () => {
    // success()
    setCustomSectionValue('')
    addSectionField ? setAddSectionField(false) : setAddSectionField(true)
  }
  const addCustomSection = () => {
    addSectionField ? setAddSectionField(false) : setAddSectionField(true)
    if (customSectionValue != '') {
      if (!plainOptions.includes(customSectionValue)) {
        setPlainOptions([...plainOptions, customSectionValue])
        setCustomOptions([...customOptions, customSectionValue])
      } else {
        failure(customSectionValue)
      }
      setTempCheckedList([...tempCheckedList, customSectionValue])
    }
  }
  const openCustomSectionDialog = () => {
    addSectionField ? setAddSectionField(false) : setAddSectionField(true)
  }

  const deleteCustomSelection = (option: string) => {
    const tempCustom = customOptions.filter((item) => item !== option)
    const tempPlain = plainOptions.filter((item) => item !== option)
    const tempCheckOptions = tempCheckedList.filter((item) => item !== option)
    setPlainOptions(tempPlain)
    setCustomOptions(tempCustom)
    setTempCheckedList(tempCheckOptions)
    success(option)
  }

  const cancelCustomSingleSection = (option: string) => {
    setCustomSectionValue('')
    const isEditableTemp = isEditable.filter((item) => item !== option)
    setIsEditable(isEditableTemp)
  }

  const addCustomSingleSection = (option: string) => {
    const newPlainOptions = plainOptions.map((item) => {
      if (item == option) {
        item = customSectionValue
      }
      return item
    })
    const newCustomOptions = customOptions.map((item) => {
      if (item == option) {
        item = customSectionValue
      }
      return item
    })
    setPlainOptions(newPlainOptions)
    setCustomOptions(newCustomOptions)
    const isEditableTemp = isEditable.filter((item) => item !== option)
    setIsEditable(isEditableTemp)
  }

  const editCustomSection = (option: string) => {
    if (!isEditable.includes(option)) {
      setIsEditable([...isEditable, option])
    }
  }
  const handleCheckboxChange = (e, option: string) => {
    const isChecked = e.target.checked
    if (isChecked) {
      setTempCheckedList([...tempCheckedList, option])
    } else {
      setTempCheckedList(tempCheckedList.filter((item) => item !== option))
    }
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return
    const items = Array.from(plainOptions)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    setPlainOptions(items)
    const updatedCheckedList = items.filter((item) => tempCheckedList.includes(item))
    setTempCheckedList(updatedCheckedList)
  }
  return (
    <>
      <Button onClick={showLoading}>
        {checkedList.length == 0 ? (
          <>
            <PlusOutlined /> Add Sections
          </>
        ) : (
          <>
            <EditOutlined /> Edit Sections
          </>
        )}
      </Button>
      <Modal
        className='add-sections-modal'
        // centered
        // style={{ top: 2 }}
        title={<h2>Adding Sections</h2>}
        footer={[
          <Button key='cancel' onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key='done'
            className={'done-button'}
            type='primary'
            onClick={() => {
              handleModalOk()
            }}
            // disabled={!validateForm()}
          >
            Done
          </Button>,
        ]}
        maskClosable={false}
        loading={loading}
        open={open}
        onCancel={() => {
          setOpen(false)
          setErrorMessage('')
        }}
      >
        <div className='info-container'>
          <span className='info-span'>
            Select the sections you want to include in your workshop.
          </span>
          <div className='buttons-container'>
            <Button
              className='unselect-button'
              onClick={unselectAll}
              disabled={tempCheckedList.length === 0}
            >
              Unselect All
            </Button>
            <Button onClick={selectAll} disabled={tempCheckedList.length == plainOptions.length}>
              Select All
            </Button>
          </div>
        </div>
        <Divider />
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='sections'>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef} className='checkbox-group'>
                {plainOptions.map((name, index) => {
                  const checkboxId = `checkbox-${name}`
                  return (
                    <Draggable key={name} draggableId={name} index={index}>
                      {(provided) => (
                        <div
                          key={name}
                          className='single-checkbox-div'
                          onChange={(e) => handleCheckboxChange(e, name)}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <label htmlFor={checkboxId} className='single-checkbox'>
                            {!isEditable.includes(name) ? (
                              <>
                                <div className='span-edit-del-container'>
                                  <span className='single-label'>
                                    <HolderOutlined className='outline-icon' /> {name}
                                  </span>
                                  {customOptions.includes(name) && (
                                    <div className='custom-section-flexbox'>
                                      <Tooltip title='edit'>
                                        <Button
                                          className='edit-delete-button'
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            editCustomSection(name)
                                          }}
                                          icon={
                                            <EditOutlined className='custom-section-icon edit-delete-icon' />
                                          }
                                        />
                                      </Tooltip>
                                      <Tooltip title='delete'>
                                        <Button
                                          className='edit-delete-button'
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            deleteCustomSelection(name)
                                          }}
                                          icon={
                                            <DeleteOutlined className='custom-section-icon edit-delete-icon' />
                                          }
                                        />
                                      </Tooltip>
                                    </div>
                                  )}
                                </div>
                                <Checkbox
                                  className='single-input custom-checkbox'
                                  checked={tempCheckedList.includes(name)}
                                  onChange={(e) => handleCheckboxChange(e, name)}
                                  id={checkboxId}
                                  name='section'
                                  value={name}
                                />
                              </>
                            ) : (
                              <div className='custom-single-section-container'>
                                <div className='custom-section-flexbox'>
                                  <Input
                                    className='custom-section-input'
                                    placeholder='Type Section Name'
                                    onChange={getInputValue}
                                  />
                                  <Tooltip title='close'>
                                    <Button
                                      className='section-button'
                                      onClick={() => cancelCustomSingleSection(name)}
                                      icon={<CloseOutlined className='custom-section-icon' />}
                                    />
                                  </Tooltip>
                                  <Tooltip title='save'>
                                    <Button
                                      className='section-button'
                                      onClick={() => addCustomSingleSection(name)}
                                      icon={<CheckOutlined className='custom-section-icon' />}
                                    />
                                  </Tooltip>
                                </div>
                              </div>
                            )}
                          </label>
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {!addSectionField ? (
          <Button
            className='add-custom-section-btn'
            icon={<PlusOutlined />}
            type='text'
            iconPosition={'start'}
            onClick={openCustomSectionDialog}
          >
            Add Custom Section
          </Button>
        ) : (
          <div className='custom-section-container'>
            <div className='custom-section-flexbox'>
              <Input
                className='custom-section-input'
                placeholder='Type Section Name'
                onChange={getInputValue}
              />
              <Tooltip title='close'>
                <Button
                  className='section-button'
                  onClick={cancelCustomSection}
                  icon={<CloseOutlined className='custom-section-icon' />}
                />
              </Tooltip>
              <Tooltip title='save'>
                <Button
                  className='section-button'
                  onClick={addCustomSection}
                  icon={<CheckOutlined className='custom-section-icon' />}
                />
              </Tooltip>
            </div>
          </div>
        )}
      </Modal>
      <ToasterMessage errorMessage={errorMessage} count={0} />
      {contextHolder}
    </>
  )
}

export default AddSections
