import React, { useContext } from 'react'
import './index.scss'
import { Modal, Button, Tooltip } from 'antd'
import { DataContext } from '../../DataContext'
import {
  goToOutputs,
  checkForMultipleFileValidation,
  checkForMultipleIcsFile,
} from '../../services/fileUploadService'
import GenerateOutputModal from '../GenerateOutputModal'

interface IGenerateOutputProps {
  checkValidation: () => boolean
  generateOutput: () => void
  projectId: string
  uploadingActive: boolean
  isModalOpen: boolean
}

const GenerateOutputButton: React.FC<IGenerateOutputProps> = (props: IGenerateOutputProps) => {
  const { uploadAllFileList, maliciousFiles, addPagesCount } = useContext(DataContext)
  const getTooltipTitle = () => {
    if (uploadAllFileList.length == 0 && !addPagesCount) {
      return 'Atleast 1 input must be there for upload before generating output'
    }
    if (checkForMultipleIcsFile('', uploadAllFileList)) {
      return 'We found more than one .ics uploaded. Kindly keep only one .ics to move ahead'
    }
    // Commenting this as this not mandoatory in the other usecases as mentioned by Diksha
    // if (!checkForMultipleFileValidation(uploadAllFileList)) {
    //   return 'There must be a combination of Meeting Invite with Audio Transcript or Meeting Recording'
    // }
    if (props.uploadingActive) {
      return 'Generate Output functionality is not allowed until current file upload completes'
    }
    if (uploadAllFileList.includes(...maliciousFiles)) {
      return 'Generate Output functionality is not allowed until malicious file is removed'
    }

    return ''
  }

  return (
    <div className='button-section'>
      <Tooltip title={getTooltipTitle()} placement='topLeft'>
        <button
          className={
            props.checkValidation() ? 'generate-output-button' : 'disabled-generate-button'
          }
          onClick={() => {
            if (props.checkValidation()) props.generateOutput()
          }}
        >
          Generate Output
        </button>
      </Tooltip>
      <div className='generateOutputModalPopup'>
        <Modal
          className='generateOutputModalPopup'
          style={{ width: '100%' }}
          title=''
          open={props.isModalOpen}
          closable={false}
          footer={[
            <Button
              key='gotoOutput'
              type='primary'
              onClick={() => {
                goToOutputs(props.projectId)
              }}
              className='goTobutton'
            >
              Go To Outputs
            </Button>,
          ]}
        >
          <GenerateOutputModal />
        </Modal>
      </div>
    </div>
  )
}
export default GenerateOutputButton
